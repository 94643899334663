.display-block {
    display: block;
}

.display-inline {
    display: inline;
}

.display-none {
    display: none;
}

.display-inline-block {
    display: inline-block;
}