.text-white {
    color: $white;
}

.text-hover-white {
    &:hover {
        color: $white;
    }
}

.text-gray {
    color: $gray;
}

.text-gray-light {
    color: $gray-light;
}

.text-gray-lighter {
    color: $gray-lighter;
}

.text-twitter-blue {
    color: $twitter-blue;
}

.text-facebook-blue {
    color: $facebook-blue;
}

.text-green {
    color: $millcroft-green;
}

.text-millcroft-gray {
    color: $millcroft-gray;
}

.text-millcroft-gray-light {
    color: $millcroft-gray-light;
}

.text-black {
    color: $black;
}

.text-pink {
    color: $millcroft-pink;
}

.text-blue {
    color: $millcroft-blue;
}

.text-v-middle {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.text-v-middle-abs {
    $size: 50px;
    @extend .abs-cover;

    display: inline-block;
    height: $size;
    position: absolute;
    top: 50% !important;
    margin-top: round($size / -2);
    line-height: $size;
}

.text-small {
    font-size: $font-size-small;
}

.text-smaller {
    font-size: $font-size-small * 0.9;
}

.text-large {
    font-size: $font-size-large;
}

.text-normal {
    font-size: $font-size-base;
}

.font-base {
    font-family: $font-family-base;
}

.font-serif {
    font-family: $font-family-serif;
}

.font-pt-sans {
    font-family: $font-family-pt-sans;
}

.font-sans-serif {
    font-family: $font-family-sans-serif;
}

.text-transform-none {
    text-transform: none;
}

.text-transform-upper {
    text-transform: uppercase;
}

.text-transform-lower {
    text-transform: lowercase;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.dont-break-out {
  overflow-wrap: break-word;
  white-space: normal;
}

.letter-spacing-normal {
  letter-spacing: normal;
}

.letter-spacing-more {
  letter-spacing: 0.1rem;
}

.letter-spacing-even-more {
    letter-spacing: 0.25rem;
}

.line-height-50 {
    line-height: 50px;
}

.text-left-md {
    @media (min-width: $screen-md-min) {
        text-align: left;
    }
}

.text-right-md {
    @media(min-width: $screen-md-min) {
        text-align: right;
    }
}


//Dynamic headings

h1 {
    font-size: $font-size-h1 * 0.5;
}

h2 {
    font-size: $font-size-h2 * 0.7;
}


@media (min-width: $screen-sm-min) {
    h1 {
        font-size: $font-size-h1 * 0.7;
    }

    h2 {
        font-size: $font-size-h2 * 0.85;
    }
}

@media (min-width: $screen-lg-min) {
    h1 {
        font-size: $font-size-h1;
    }

    h2 {
        font-size: $font-size-h2;
    }
}