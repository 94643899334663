$mv: $padding-base-vertical;
$mh: $padding-base-horizontal;

$selectors: '', '-t', '-r', '-b', '-l', '-h', '-v';
$css: '', '-top', '-right', '-bottom', '-left', '-top', '-horizontal', '-vertical';

@for $i from 1 through length($selectors)
{
    $selector: nth($selectors, $i);
    $c: nth($css, $i);

    @for $x from 0 through 5
    {
        .p#{$selector}-#{$x} {
            @if ($selector == '-h') {
                padding-left: ($mv * $x) !important;
                padding-right: ($mv * $x) !important;
            }
            @elseif ($selector == '-v') {
                padding-top: ($mv * $x) !important;
                padding-bottom: ($mv * $x) !important;
            }
            @else {
                padding#{$c}: ($mv * $x) !important;
            }
        }
    }
}