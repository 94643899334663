.form-control-ghost {
    background-color: transparent;
}

.btn-ghost {
    background-color: transparent;

    &.btn-primary {
        border-color: $white;

        &:hover {
            background-color: $brand-primary;
            background-color: rgba(255,255,255, 0.2);
        }
    }
}

.btn-pill {
    border-radius: 15px;
}

.form-inline {

    margin-left: -12px;

    .form-item {
        vertical-align: bottom;
        margin-top: 8px;

        &.has-label {
            margin-left: 12px;
        }
    }

    .control-label {
        display: block;
    }
}