.xlvets-logo {
    $width: 241px;
    $height: 249px;
    $ratio: 0.3;

    display: inline-block;
    background: url('../images/logos/xlvets-white.svg') 50% 50% no-repeat;
    width: round($width * $ratio);
    height: round($height * $ratio);
    background-size: contain;
}

.rcvs-logo {
    $width: 300px;
    $height: 211px;
    $ratio: 0.32;

    display: inline-block;
    background: url('../images/logos/rcvs.png') 50% 50% no-repeat;
    width: round($width * $ratio);
    height: round($height * $ratio);
    background-size: contain;
}

.cat-clinic-logo {
    $width: 300px;
    $height: 171px;
    $ratio: 0.40;

    display: inline-block;
    background: url('../images/logos/cat-friendly-clinic.png') 50% 50% no-repeat;
    width: round($width * $ratio);
    height: round($height * $ratio);
    background-size: contain;
}
