.carousel-title,
.carousel-link {
    @extend .carousel-caption;
}

.slideshow {
    height: 200px;
    overflow-y: hidden;

    .item {
        @extend .background-pink;
        @extend .font-sans-serif;
        @extend .letter-spacing-more;
        @extend .background-arrows;

        min-height: 200px;
        overflow: hidden;

        > .container-max {
            background-repeat: no-repeat;
            background-size: auto 80%;
            background-position: 0 50%;
            position: relative;
            min-height: 200px;
            z-index: 1000;
        }

        .carousel-caption {
            bottom: auto;
            top: 65px;
        }

        .carousel-title {
            @extend .text-left;
            bottom: 40px;
            right: -10px;
            left: auto;
            top: auto;
            width: 45%;
            font-weight: 900;
            font-size: $font-size-base * 0.9;
            line-height: $font-size-base * 1.1;
        }
    }

    .carousel-control {
        &.left,
        &.right {
            background: none transparent;
        }
    }
}

@media (min-width: $screen-xs-min) {
    .slideshow {
        height: 250px;

        .item {
            min-height: 250px;

            .carousel-title {
                display: block;
                font-size: $font-size-base * 1.2;
                line-height: round($font-size-base * 1.2 * 1.1);
                bottom: 55px;
                width: 40%;
                right: 40px;
            }

            .container-max {
                min-height: 250px;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .slideshow {
        height: 350px;

        .item {
            min-height: 350px;


            .carousel-title {
                width: 40%;
                font-size: $font-size-base * 1.8;
                line-height: round($font-size-base * 1.8 * 1.1);
            }

            .container-max {
                min-height: 350px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .slideshow {
        height: 450px;

        .item {
            min-height: 450px;

            .carousel-title {
                width: 40%;
                font-size: $font-size-base * 2.6;
                line-height: round($font-size-base * 2.6 * 1.1);
                bottom: 70px;
            }

            .container-max {
                min-height: 450px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .slideshow {
        height: 550px;

        .item {
            min-height: 550px;

            .carousel-title {
                font-size: round($font-size-h1 * 1.1);
                line-height: round($font-size-h1 * 1.1 * 1.1);
                right: 80px;
                bottom: 100px;
            }

            .container-max {
                min-height: 550px;
            }
        }
    }
}

@media (min-width: 1440px) {
    .slideshow {
        height: 600px;

        .item {
            min-height: 600px;

            .carousel-title {
                width: 30%;
                font-size: round($font-size-h1 * 1.2);
                line-height: round($font-size-h1 * 1.2 * 1.1);
                right: 240px;

            }

            .container-max {
                min-height: 600px;
            }
        }
    }
}