$mv: $padding-base-vertical;
$mh: $padding-base-horizontal;

$selectors: '', '-t', '-r', '-b', '-l', '-h', '-v';
$css: '', '-top', '-right', '-bottom', '-left', '-top', '-horizontal', '-vertical';

@for $i from 1 through length($selectors)
{
    $selector: nth($selectors, $i);
    $c: nth($css, $i);

    @for $x from 0 through 5
    {
        .m#{$selector}-#{$x} {
            @if ($selector == '-h') {
                margin-left: ($mv * $x) !important;
                margin-right: ($mv * $x) !important;
            }
            @elseif ($selector == '-v') {
                margin-top: ($mv * $x) !important;
                margin-bottom: ($mv * $x) !important;
            }
            @else {
                margin#{$c}: ($mv * $x) !important;
            }
        }
    }
}

.m-h-auto {
    margin-left: auto;
    margin-right: auto;
}
