.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.abs-cover {
    @extend .position-absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.vertical-align-middle {
    vertical-align: middle;
}