.navbar-main {
    @extend .m-t-0;

    > .container-fluid {
        @extend .border-blue;
        @extend .b-t-1;
        @extend .b-b-1;
        @extend .m-v-2;
    }
}

.navbar-default {
    .navbar-nav {
        > li {

            > a {
                @extend .text-small;
                @include link-color($navbar-default-link-color);

                &:hover,
                &:focus {
                    color: $navbar-default-link-hover-color;
                }
            }

            &.active {
                > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-default-link-active-color;
                    }
                }
            }
        }
    }
}

.nav.secondary {
    > li {
        display: inline-block;

        > a {
            @extend .link-white;
            text-transform: none;
            padding-left: 6px;
            padding-right: 6px;

            &.text-transform-upper {
                text-transform: uppercase;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
                background-color: transparent;
            }
        }

        &:last-child {
            text-align: right;
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
    .navbar-main {
        background-color: $navbar-default-bg;

        > .container-fluid {
           margin-top: 0 !important;
           margin-bottom: 0 !important;
           border: 0 none;
        }
    }

    .navbar-default {
        > li {
            &:last-child {
                > a {
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .nav {
        &.secondary {
            font-size: $font-size-small;
        }
    }
}