.pink-dot-after {
    position: relative;

    &:after {
        $size: 8px;
        content: "";
        position: absolute;
        width: $size;
        height: $size;
        border-radius: $size;
        right: $size / -2;
        top: 50%;
        margin-top: $size / -2;
        background-color: $millcroft-pink;
    }
}

.pink-dot-before {
    position: relative;

    &:before {
        $size: 8px;
        content: "";
        position: absolute;
        width: $size;
        height: $size;
        border-radius: $size;
        left: $size / -2;
        top: 50%;
        margin-top: $size / -2;
        background-color: $millcroft-pink;
    }
}

.no-br,
.dont-break {
    white-space: nowrap;
}

.ok-br,
.do-break {
    white-space: normal;
}

.background-arrows {
    position: relative;
    $height: 500px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: -100px;
        right: -40px;
        height: $height;
        top: ($height * -1) + 20px;
        background-color: #fff;
        transform-origin: 100% 100%;
        transform: rotate(-10deg) skewX(10deg);
        z-index: 100;
    }

    &:after {
        top: auto;
        bottom: ($height * -1) + 40px;
        transform: rotate(3deg) skewX(-3deg);
    }

    @media (min-width: $screen-lg-min) {
        &:before {
            transform: rotate(-6deg) skewX(6deg);
        }

        &:after {
            transform: rotate(1.5deg) skewX(-1.5deg);
        }
    }
}

.line-after {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 3px;
        background-color: $gray;
    }
}

.line-after-pink {
    &:after {
        background-color: $millcroft-pink;
    }
}

.line-after-center {
    &:after {
        $width: 120px;
        left: 50%;
        width: $width;
        margin-left: $width / -2;
    }
}

.mh-a {
    margin-left: auto;
    margin-right: auto;
}