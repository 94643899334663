.calendar-style {
    $size: 70px;
    $padding: 5px;
    width: $size;
    height: $size;
    padding: $padding;

    @extend .text-center;

    .day {
        font-size: $font-size-base * 2;
        display: block;
        font-weight: bold;
    }

    .month {
        @extend .text-transform-upper;
        display: block;
        margin-top: -5px;
    }
}