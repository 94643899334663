.navbar {
    border: 0 none;
}

.navbar-inverse {
    min-height: $navbar-inverse-height;

    .navbar-nav {
        > li > a {
            @media(min-width: $grid-float-breakpoint) {
                $padding-vertical: (($navbar-inverse-height - $line-height-computed) / 2);
                padding-top: $padding-vertical;
                padding-bottom: $padding-vertical;
            }
        }
    }
}

.navbar-brand {
    font-size: $font-size-base;
    padding-left: 0;
    margin-left: -8px;
}

.navbar-toggle {
    margin-right: -7px;
}

.navbar-default {
    .navbar-brand {
        @extend .text-blue;
    }
}

.dropdown-menu {
    font-size: $font-size-small;

    > li {
        > a {
            font-weight: 300;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .col-lg-24 {
        width: 20%;
    }
}