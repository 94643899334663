.background-gray-lighter {
    background-color: $gray-lighter;
}

.background-white {
    background-color: $white;
}

.background-beige {
    background-color: $millcroft-beige;
}

.background-green {
    background-color: $millcroft-green;
}

.background-green-dark {
    background-color: $millcroft-green-dark;
}

.background-blue {
    background-color: $millcroft-blue;
}

.background-pink {
    background-color: $millcroft-pink;
}

.background-cover {
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
}

.background-vertical-middle {
    background-position-y: 50%;
}

.background-horizontal-middle {
    background-position-x: 50%;
}

.background-blend-screen {
    background-blend-mode: screen;
}

.background-blend-multiply {
    background-blend-mode: multiply;
}