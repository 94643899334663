.node-type-paragraphs-page {
    .page-header {
        display: none;
    }
}

.set-text-white {
    @extend .text-white;
    @extend .child-links-white;

    .title {
        @extend .text-white;

        &:after {
            background-color: $white;
        }
    }
}