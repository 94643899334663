.region-top-contact {
    font-size: $font-size-base * 2;
    float: left;
}

.region-top-search {
    float: right;
    @extend .hidden-xs;
}

.top-social {
    line-height: 60px;

    li {
        @extend .m-h-1;
    }

    .email {
        span {
            font-size: $font-size-base * 1.2;
            color: $millcroft-gray;
        }
    }
}

.navbar-nav {
    > li {
        > a {
            @extend .link-gray;
        }
    }
}