.branches-list {
    @extend .clearfix;
    padding-left: 15px;
    padding-right: 9px;

    > li {
        @extend .pink-dot-after;
        width: 20%;
        text-align: center;
        float: left;
        text-transform: uppercase;
        position: relative;

        &:first-child {
            @extend .pink-dot-before;
        }
    }
}

.emergency-details {
    text-align: center;
    @extend .m-b-3;
    @extend .text-blue;

    h3 {
        @extend .font-pt-sans;
        font-size: $font-size-base * 1.2;
        text-transform: uppercase;
        font-weight: 300;

        a {
            @extend .link-pink;
            font-weight: bold;
        }
    }
}

@media (min-width: $screen-xs-min) {
    .emergency-details {
        h3 {
            font-size: $font-size-base * 1.4;
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
    .emergency-details {
        text-align: right;

        h3 {
            font-size: $font-size-base * 1.3;
        }
    }
}