$width: 500px;
$height: 152px;

.logo {
    @extend .m-h-auto;
    @extend .m-t-4;
    $ratio: 0.5;
    background: url('../images/logos/millcroft.svg') 50% 50% no-repeat transparent;
    background-size: 100% 100%;
    width: round($width * $ratio);
    height: round($height * $ratio);
    display: block;
}

@media (min-width: $grid-float-breakpoint) {
    .logo {
        $ratio: 0.4;
        width: round($width * $ratio);
        height: round($height * $ratio);
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: $screen-md-min) {
    .logo {
        $ratio: 0.6;
        width: round($width * $ratio);
        height: round($height * $ratio);
    }
}

@media (min-width: $screen-lg-min) {
    .logo {
        $ratio: 0.8;
        width: round($width * $ratio);
        height: round($height * $ratio);
    }
}
