html,
body {
    font-weight: 300;
}

blockquote {
    font-style: italic;
    text-align: center;
    border-left: 0 none;
    font-size: $font-size-h4;
    @extend .m-t-5;
    @extend .m-b-0;
    @extend .p-h-0;
    @extend .font-serif;
    @extend .m-h-auto;

    > p {
        display: inline;

        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
    blockquote {
        max-width: 80%;
    }
}

@media (min-width: $screen-md-min) {
    blockquote {
        max-width: 60%;
    }
}

@media (min-width: $screen-lg-min) {
    blockquote {
        max-width: 40%;
    }
}