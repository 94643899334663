.icon-link {
    text-align: center;
}

.icon-round {
    border-radius: 100%;
    overflow: hidden;
}

.icon-large {
    font-size: $font-size-base * 2;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.icon {
    $size: 100px;
    @extend .m-h-auto;
    display: inline-block;
    width: $size;
    height: $size;
    background: transparent 50% 50% no-repeat;
    background-size: 65%;
}

.icon-small {
    $size: 45px;
    width: $size;
    height: $size;
}

.icon-bordered {
    border: 3px solid $millcroft-green;
}

.icon-sheep {
    background-image: url('../images/icons/blue-sheep.svg');
}

.icon-cat {
    background-image: url('../images/icons/blue-cat.svg');
    background-size: 50%;
}

.icon-horse {
    background-image: url('../images/icons/blue-horse.svg');
    background-size: 76%;
}

.icon-shop {
    background-image: url('../images/icons/blue-shop.svg');
    background-size: 50%;
}