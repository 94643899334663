.border-inset {

    &::after {
        @extend .abs-cover;

        content: "";
        display: block;
        border: 1px solid transparent;
    }
}

@for $i from 1 through 5 {
    .border-inset-#{$i}0 {
        &::after {
            top: ($i * $padding-base-vertical);
            bottom: ($i * $padding-base-vertical);
            left: ($i * $padding-base-vertical);
            right: ($i * $padding-base-vertical);
        }
    }
}

.border-inset-white {
    &::after {
        border-color: $white;
    }
}

.border-none {
    border: none;
}

.border-white {
    border-color: $white;
}

.border-millcroft-gray {
    border-color: $millcroft-gray;
}

.border-millcroft-gray-light {
    border-color: $millcroft-gray-light;
}

.border-gray {
    border-color: $gray;
}

.border-pink {
    border-color: $millcroft-pink;
}

.border-blue {
    border-color: $millcroft-blue;
}

.border-right-small-white {
    position: relative;

    &:after {
        $height: round($font-size-base * 0.8);
        content: "";
        display: inline-block;
        width: 1px;
        height: $height;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: round($height / -2);
        background-color: $white;
    }
}

.border-right-small-none {
    &:after {
        display: none;
    }
}

$selectors: '', '-t', '-r', '-b', '-l', '-h', '-v';
$css: '', '-top', '-right', '-bottom', '-left', '-top', '-horizontal', '-vertical';
$px: 1px;

@for $i from 1 through length($selectors)
{
    $selector: nth($selectors, $i);
    $c: nth($css, $i);

    @for $x from 0 through 10
    {
        .b#{$selector}-#{$x} {
            @if ($selector == '-h') {
                border-left-width: $px * $x;
                border-right-width: $px * $x;
                border-left-style: solid;
                border-right-style: solid;
            }
            @elseif ($selector == '-v') {
                border-top-width: $px * $x;
                border-bottom-width: $px * $x;
                border-top-style: solid;
                border-bottom-style: solid;
            }
            @else {
                border#{$c}-width: ($px * $x);
                border#{$c}-style: solid;
            }
        }
    }
}