.block-title {
    @extend .title;
    //@extend .border-millcroft-gray;
    //@extend .b-b-1;
    @extend .p-b-2;
}

.branch-details {
    .block-title {
        @extend .sub-title;
        @extend .text-center;
        @extend .text-right-md;
        @extend .text-transform-upper;
        @extend .b-b-0;
        font-size: $font-size-h4;
    }
}

@media (min-width: $screen-md-min) {
    .branch-details {
        .block-title {
        }
    }
}