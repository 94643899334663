.clear-left {
    clear: left;
}

.clear-right {
    clear: right;
}

.clear-both {
    clear: both;
}

.float-left-xs {
    float: left;
}

.float-right-xs {
    float: right;
}

.float-none-xs {
    clear: both;
}


@media (min-width: $screen-sm-min) {
    .float-left-sm {
        float: left;
    }

    .float-right-sm {
        float: right;
    }

    .float-none-sm {
        float: none;
    }
}