@for $i from 0 through 10 {
    .opacity-#{$i}0 {
        opacity: $i / 10;
    }

    .opacity-hover-#{$i}0 {
        &:hover,
        &:active {
            opacity: $i / 10;
        }
    }
}