@mixin link-color($color) {
    color: $color;

    &:link,
    &:hover,
    &:active,
    &:visited {
        color: $color;
    }
}

.link-black {
    @include link-color($black);
}

.link-white {
    @include link-color($white);
}

.link-gray-lighter {
    @include link-color($gray-lighter);
}

.link-gray-light {
    @include link-color ($gray-light);
}

.link-gray {
    @include link-color($gray);
}

.link-twitter-blue {
    @include link-color($twitter-blue);
}

.link-facebook-blue {
    @include link-color($facebook-blue);
}

.link-green {
    @include link-color($millcroft-green);
}

.link-millcroft-gray {
    @include link-color($millcroft-gray);
}

.link-millcroft-gray-light {
    @include link-color($millcroft-gray-light);
}

.link-millcroft-green-darker {
    @include link-color($millcroft-green-darker);
}

.link-pink {
    @include link-color($millcroft-pink);
}


.child-links-white {
    a {
        @extend .link-white;
    }
}
