.call-to-action-buttons {
    @extend .row;

    &.cols-1 {
        .call-to-action-button {
            @extend .col-xs-12;
        }
    }

    &.cols-2 {
        .call-to-action-button {
            @extend .col-xs-12;
            @extend .col-sm-6;
        }
    }

    &.cols-3 {
        .call-to-action-button {
            @extend .col-xs-12;
            @extend .col-sm-6;
            @extend .col-md-4;
        }
    }

    &.cols-4 {
        .call-to-action-button {
            @extend .col-xs-12;
            @extend .col-sm-6;
            @extend .col-md-3;
        }
    }

    .btn {
        @extend .font-pt-sans;
        @extend .letter-spacing-more;
        width: 140px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        font-size: $font-size-small * 0.8;
    }

    .icon-wrapper {
        width: 20%;
        float: left;
        padding-left: 20px;

        .icon {
            background-position: 50% 50%;

            $size: 70px;

            width: $size;
            //height: $size;
        }
    }

    .content-wrapper {
        float: left;
        width: 80%;
    }

    .sub-title {
        font-size: $font-size-base;
    }
}

@media (min-width: $screen-xs-min) {
    .call-to-action-buttons {
        .sub-title,
        .button-wrapper {
            width: 40%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            margin-top: 0;
            margin-bottom: 0;
        }

        .button-wrapper {
            width: 60%;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .call-to-action-buttons {
        .button-wrapper {
            width: 54%;
        }

        .icon-wrapper {
            padding-left: 0;
        }
    }
}

@media (min-width: $screen-md-min) {
    .call-to-action-buttons {
        .icon-wrapper,
        .content-wrapper {
            float: none;
            width: auto;
            padding-left: 0;

            .icon {
                background-position: 50% 50%;
                width: 100px;
            }
        }

        .sub-title,
        .button-wrapper {
            float: none;
            width: auto;
            height: auto;
        }

        .sub-title {
            margin-top: $padding-large-vertical;
            margin-bottom: $padding-large-vertical;
            font-size: $font-size-base * 1.2;
            padding-left: 30px;
            padding-right: 30px;
        }

        .button-wrapper {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
        }

        .call-to-action-button {
            > .match-height {
                padding-bottom: 80px;
            }
        }
    }
}