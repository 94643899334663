.footer {
    .title {
        color: $white;
    }

    .branch-address {
        .block-title {
            font-size: $font-size-h3;
            font-weight: 900;
            border-bottom: 0;
        }
    }
}

.website-author {
    a {
        text-decoration: none;
        transition: color 400ms;

        span {
            position: relative;

            &:after {
                content: "";
                height: 1px;
                width: 0px;
                background-color: $white;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: width 700ms;
            }
        }

        &:hover {
            color: lighten($gray-light, 15%);

            span {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

.google-map {
    margin-bottom: -5px;
}

.branch-footer {
    .branch {
        h4 {
            font-weight: bold;
            font-size: $font-size-h6;
        }

        p {
            font-size: $font-size-small * 0.9;
        }
    }
}

.region-legal-menu {
    .nav {
        > li {
            display: inline-block;

            > a{
                &:hover,
                &:focus {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }

            &:last-child {
                > a {
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }
}