.title {
    @extend .font-base;
    @extend .text-millcroft-gray;
    @extend .line-after;
    @extend .p-b-5;
    @extend .m-b-5;
    font-weight: 300;

    a {
        @extend .link-millcroft-gray;
    }
}

.sub-title {
    @extend .font-pt-sans;
    @extend .text-millcroft-gray-light;
    @extend .text-bold;

    a {
        @extend .link-millcroft-gray-light;
    }
}

.page-header {
    @extend .title;
    @extend .line-after-pink;
}